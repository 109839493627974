import React, { useRef, createContext } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useHistory } from 'react-router-dom';

export const ScrollContext = createContext({ scrollbar: null, isOnTop: null });
export const { Provider, Consumer } = ScrollContext;

const Scrollbar = props => {
	const history = useHistory();
	const scrollbar = useRef(null);
	history.listen(h => scrollbar.current?.scrollToTop());
	const scroll = (
		<Scrollbars
			autoHide
			ref={scrollbar}
			autoHideTimeout={1000}
			autoHideDuration={200}
			style={{ height: '100vh' }}>
			<Provider value={{ scrollbar: scrollbar }}>{props.children}</Provider>
		</Scrollbars>
	);
	return scroll;
};

export default Scrollbar;
