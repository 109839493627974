import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';

const useStyle = makeStyles(theme => ({
	root: {
		position: 'absolute',
		height: '100%',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'& > *': {
			color: theme.palette.action.main,
		},
	},
}));

const LoadingCover = () => {
	const style = useStyle();
	return (
		<div className={style.root}>
			<CircularProgress />
		</div>
	);
};

export default LoadingCover;
