import attorneys from './attorney.data';

export const themeObj = (txtColor = 'light', bgColor = 'dark', position = 'sticky', alpha = 1) => ({
	txt: txtColor,
	bg: bgColor,
	pos: position,
	alpha: alpha,
});

export const data = {
	links: [
		['Home', '/home'],
		[
			'About Us',
			'/about-us',
			[
				[
					'Legal Team',
					'/legal-team',
					attorneys.map(att => [att.profile.shortName, att.path]),
				],
			],
		],
		['Areas of Law Practice', '/areas-of-law-practices'],
		// ['Blog', '/blog'],
		['Contact Us', '/contact-us'],
	],
	pathThemes: {
		'/home': themeObj('light', 'dark', 'absolute', 0.6),
		'/notfound': themeObj('light', null, 'absolute'),
		'/contact-us': themeObj('light', null, 'absolute'),
		'/questions/': themeObj('light', 'dark', 'static'),
		'/blog/': themeObj('light', 'dark', 'static'),
		'/areas-of-law-practices': themeObj('light', 'dark', 'static'),
		'/areas-of-law-practices/': themeObj('light', 'dark', 'sticky'),
	},
	initialTheme: {
		alpha: 1,
		pos: null,
		bgColor: null,
		txtColor: null,
	},
};
