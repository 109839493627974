import React, { Fragment } from 'react';
import areas from '../../../Data/areas.data';
import { data } from '../../../Data/navbar.data';
import MobNestedMenuItem from '../MobNestedMenuItem/MobNestedMenuItem';
import cloneDeep from 'clone-deep';

const newData = cloneDeep(data);
const links = newData.links;
links[2].push(areas.map(area => [area.name, area.path]));

const MobDrawerItems = ({ mobData = links, nestedLink = null, level = 0 }) => {
	return (
		<Fragment>
			{mobData.map((item, i) => {
				return (
					<MobNestedMenuItem
						key={i}
						name={item[0]}
						link={nestedLink ? nestedLink + item[1] : item[1]}
						level={level}>
						{item[2] && (
							<MobDrawerItems
								parent={item}
								mobData={item[2]}
								level={level + 1}
								nestedLink={(nestedLink ? nestedLink : '') + item[1]}
							/>
						)}
					</MobNestedMenuItem>
				);
			})}
		</Fragment>
	);
};

export default MobDrawerItems;
