import React, { lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

const Home = lazy(() => import('../Pages/Home'));
const AboutUs = lazy(() => import('../Pages/AboutUs'));
const AreasOfLawPractices = lazy(() => import('../Pages/AreasOfLaw'));
const NotFound = lazy(() => import('../Pages/NotFound/NotFound'));
const FAQs = lazy(() => import('../Pages/FAQs/FAQs'));
const ContactUs = lazy(() => import('../Pages/ContactUs/ContactUs'));
const Questions = lazy(() => import('../Pages/Questions/Questions'));
const Blog = lazy(() => import('../Pages/Blog'));

const Routes = () => {
	return (
		<Switch>
			<Route path='/home' component={Home} />
			<Redirect exact from='/' to='/home' />

			<Route path='/about-us' component={AboutUs} />

			<Route path='/faq' component={FAQs} />

			<Route path='/contact-us' component={ContactUs} />

			<Route path='/areas-of-law-practices' component={AreasOfLawPractices} />

			<Route path='/blog' component={Blog} />

			<Route path='/questions/:category/:question' component={Questions} />

			<Route path='/notfound' component={NotFound} />

			<Redirect status={404} to='/notfound' component={NotFound} />
		</Switch>
	);
};

export default Routes;
