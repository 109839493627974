import React, { Fragment } from 'react';
import {
	Container,
	Grid,
	Box,
	IconButton,
	Button,
	ListItem,
	List,
	Hidden,
} from '@material-ui/core';
import { LocationOn, Phone, Twitter, Facebook } from '@material-ui/icons';
import { Link } from 'react-router-dom';

import data from '../../Data/footer.data';
import MultiLine from '../MultiLine';
import { useStyle } from './useStyle';
import socialLinks from '../../Data/socialLinks.data';

const Footer = () => {
	const classes = useStyle();
	const desktop = (
		<Fragment>
			<Grid item xs={12} sm={6} md={6}>
				<Grid container style={{ height: '100%' }}>
					{Object.entries(data.items.desktop)
						.map(el => ({ title: el[0], links: el[1] }))
						.map((item, i) => (
							<Grid item xs={12} md={i === 0 ? 8 : 4} key={i}>
								<h3>{item.title}</h3>
								<List style={{ paddingTop: 0 }}>
									{item.links.map((ItemLink, i) => (
										<Link to={ItemLink[1]} key={i}>
											<ListItem>
												<Button className={classes.itemButton}>
													{ItemLink[0]}
												</Button>
											</ListItem>
										</Link>
									))}
								</List>
							</Grid>
						))}
				</Grid>
			</Grid>
			<Grid item xs={12} sm={6} md={3}>
				<h3>Contact Information</h3>
				<LocationOn className={classes.icon} />
				{data.location.name}
				<MultiLine text={data.location.address} />
				<Box className={classes.socialMedia}>
					<h3>Social Media</h3>
					<Box>
						<IconButton href={socialLinks.facebook} target='_blank'>
							<Facebook fontSize='large' className='icons' />
						</IconButton>
						<IconButton
							href={socialLinks.twitter}
							target='_blank'
							style={{ marginLeft: 'auto' }}>
							<Twitter fontSize='large' className='icons' />
						</IconButton>
					</Box>
				</Box>
			</Grid>
			<Grid item xs={12} sm={6} md={3} style={{ marginTop: '55px' }}>
				<Phone className={classes.icon} />
				<MultiLine text={`${data.contact.phone}\n${data.contact.fax}`} />
			</Grid>
		</Fragment>
	);

	const mobile = (
		<Fragment>
			<Grid item xs={6}>
				<h3>Our Company</h3>
				{data.items.mobile['Our Company']
					.map(el => ({ title: el[0], link: el[1] }))
					.map((item, i) => (
						<Link to={item.link} key={i}>
							<ListItem>
								<Button className={classes.itemButton}>{item.title}</Button>
							</ListItem>
						</Link>
					))}
				<Box ml={-1.7}>
					<IconButton href={socialLinks.facebook} target='_blank'>
						<Facebook fontSize='large' className='icons' />
					</IconButton>
					<IconButton
						href={socialLinks.twitter}
						target='_blank'
						style={{ marginLeft: 'auto' }}>
						<Twitter fontSize='large' className='icons' />
					</IconButton>
				</Box>
			</Grid>
			<Grid className='mobConInfo' item xs={6}>
				<h3>Contact Information</h3>
				<h4>Call:</h4>
				<MultiLine text={`${data.contact.phone}\n${data.contact.fax}`} />
				<h4>Address:</h4>
				<MultiLine text={data.location.address} />
			</Grid>
		</Fragment>
	);

	return (
		<footer className={classes.root}>
			<Box className={classes.first}>
				<Container className={classes.firstContainer}>
					<Grid container spacing={4}>
						<Hidden smDown>{desktop}</Hidden>
						<Hidden smUp>{mobile}</Hidden>
					</Grid>
				</Container>
			</Box>
			<div className={classes.second}>
				<Container className={classes.secondContainer}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>
							{data.copyRight.left}
						</Grid>
						<Grid item xs={12} md={6}>
							{data.copyRight.right}
						</Grid>
					</Grid>
				</Container>
			</div>
		</footer>
	);
};

export default Footer;
