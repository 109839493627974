import { makeStyles } from '@material-ui/core';

export const useStyle = makeStyles(theme => {
	const {
		palette,
		breakpoints: { down, up },
		spacing,
	} = theme;
	return {
		root: {
			fontFamily: 'Montserrat',
			lineHeight: '25px',
			alignSelf: 'bottom',
			'& h3': {
				fontSize: '1.1rem',
			},
			'& h4': {
				marginBottom: spacing(1),
				'&:nth-child(5)': {
					marginTop: spacing(3),
				},
			},
			'& li': {
				padding: '0 !important',
			},
			'& .icons': {
				color: 'white',
			},
			'& .mobConInfo > div': {
				fontWeight: '300',
				fontSize: '0.9rem',
				lineHeight: '1.8rem',
				opacity: '0.',
			},
		},
		first: {
			width: '100%',
			background: palette.primary.main,
			color: palette.primary.light,
		},
		firstContainer: {
			[up('sm')]: {
				padding: spacing(4, 0, 5, 0),
			},
			'& > *': {
				display: 'flex',
				flexDirection: 'flex-start',
				[down('sm')]: {
					padding: spacing(0, 2, 4, 2),
				},
			},
			'& .MuiButton-label': {
				justifyContent: 'left',
				textTransform: 'none',
				fontSize: '17px',
				[down('sm')]: {
					fontSize: '0.85rem',
					fontWeight: '300',
					opacity: '0.9',
				},
			},
			'& .MuiGrid-item': {
				padding: 0,
			},
			'& h3': {
				fontSize: '1rem',
			},
		},
		second: {
			width: '100%',
			background: palette.primary.light,
			color: palette.primary.main,
			fontWeight: '600',
		},
		secondContainer: {
			padding: spacing(2),
			fontSize: '16px',
			[down('sm')]: {
				fontSize: '0.9rem',
				'& .MuiGrid-item': {
					padding: spacing(0.5, 0, 0.5, 1),
				},
			},
		},
		icon: {
			float: 'left',
			marginBottom: '28px',
			marginRight: '12px',
			[down('md')]: {
				marginBottom: '14vh',
			},
		},
		itemButton: {
			color: palette.primary.light,
			fontFamily: 'inherit',
			fontSize: '15px',
			padding: spacing(0.7, 0.5, 0.7, 0),
			textAlign: 'left',
		},
		socialMedia: {
			marginTop: spacing(2),
			'& h3': {
				float: 'left',
				marginRight: spacing(2.5),
			},
		},
	};
});
