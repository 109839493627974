import React, { useState, useRef } from 'react';
import rightArrow from './../../../Assets/icons/right-arrow.svg';
import {
	Paper,
	Button,
	List,
	ListItem,
	Popover,
	ListItemText,
	ListItemSecondaryAction,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import './NestedMenu.css';
import { useStyle } from './useStyle';
import { Fragment } from 'react';

const NestedMenu = ({ btnClass, links }) => {
	const classes = useStyle();
	const [anchorEl, setAnchorEl] = useState(null);
	const [subAnchorEl, setSubAnchorEl] = useState(null);
	const anchorRef = useRef(null);

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const handleSubClick = event => {
		event.preventDefault();
		setSubAnchorEl(event.currentTarget);
	};
	const handleSubClose = () => {
		setSubAnchorEl(null);
		// setAnchorEl(null);
	};

	const handleMainClick = event => {
		event.preventDefault();
		setAnchorEl(event.currentTarget);
	};
	const handleMainClose = () => {
		setSubAnchorEl(null);
		setAnchorEl(null);
	};

	const popConfig = {
		main: {
			anchorOrigin: {
				vertical: 'bottom',
				horizontal: 'center',
			},
			transformOrigin: {
				vertical: 'top',
				horizontal: 'center',
			},
		},
		sub: {
			anchorOrigin: {
				vertical: 'center',
				horizontal: 'right',
			},
			transformOrigin: {
				vertical: 'top',
				horizontal: 'left',
			},
		},
	};

	return (
		<div className={classes.root}>
			<Button
				disableFocusRipple
				ref={anchorRef}
				className={btnClass}
				onClick={handleMainClick}>
				{links[0]}
			</Button>
			<Popover
				id={id}
				open={open}
				{...popConfig.main}
				anchorEl={anchorRef.current}
				onClose={handleMainClose}
				onClick={e => e.preventDefault()}>
				<Paper className={classes.paper} style={{ marginTop: '18px' }}>
					<List>
						{links[2].map((fb, i) => (
							<Fragment>
								{!fb[2] ? (
									<Link to={links[1] + fb[1]} key={i}>
										<ListItem onClick={handleMainClose} button>
											<ListItemText>{fb[0]}</ListItemText>
										</ListItem>
									</Link>
								) : (
									<div>
										<ListItem ref={subAnchorEl} onClick={handleSubClick} button>
											<ListItemText>{fb[0]}</ListItemText>
											<ListItemSecondaryAction ref={subAnchorEl}>
												<img src={rightArrow} alt='' />
											</ListItemSecondaryAction>
										</ListItem>
										<Popover
											id={Boolean(subAnchorEl) ? 'simple-popover' : undefined}
											open={Boolean(subAnchorEl)}
											anchorEl={subAnchorEl}
											onClose={handleSubClose}
											{...popConfig.sub}
											style={{ marginTop: '-10px' }}
											onClick={e => e.preventDefault()}>
											<Paper
												className={classes.paper}
												style={{ marginLeft: '5px' }}>
												<List>
													{fb[2].map((sb, i) => (
														<Link to={links[1] + fb[1] + sb[1]} key={i}>
															<ListItem
																onClick={handleMainClose}
																button>
																<ListItemText>{sb[0]}</ListItemText>
															</ListItem>
															{i !== fb[2].length - 1 && (
																<div className={classes.divider} />
															)}
														</Link>
													))}
												</List>
											</Paper>
										</Popover>
									</div>
								)}
							</Fragment>
						))}
					</List>
				</Paper>
			</Popover>

			{/* <Popper open={open} anchorEl={anchorRef.current} transition disablePortal></Popper> */}
		</div>
	);
};

export default NestedMenu;
