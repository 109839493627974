import { makeStyles } from '@material-ui/core';

export const useStyle = themeObj =>
	makeStyles(theme => {
		const {
			palette,
			breakpoints: { down },
			spacing,
		} = theme;
		const { txtColor: txt, bgColor: bg, alpha, pos } = themeObj;
		const textColor = txt === 'dark' ? palette.primary.main : 'white';
		let bgColor = bg === 'dark' ? palette.primary.main : 'none';
		if (alpha !== 1) bgColor = `rgba(43, 45, 47,${alpha})`;
		return {
			appBar: {
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				padding: spacing(2.5, 0),
				background: bgColor,
				position: pos,
				[down('lg')]: {
					padding: spacing(themeObj.bgColor == null ? 3.5 : 1.5, 0),
				},
				[down('sm')]: {
					background: palette.primary.main,
					padding: spacing(0.8, 0),
					position: 'sticky',
				},
			},
			logo: {
				filter: `invert(${txt === 'dark' ? '0' : '1'})`,
				height: '60px',
				margin: spacing(0, 4, -0.5, 4),
				float: 'left',
				[down('lg')]: {
					height: '50px',
					margin: spacing(0, 4, -0.5, 3),
				},
				[down('sm')]: {
					height: '38px',
					margin: '0',
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%,-50%)',
				},
			},
			hamburger: {
				'& .hamburger-react > div > *': {
					height: '1px !important',
				},
			},
			consultation: {
				width: '10vw',
				marginLeft: '1vw',
				marginRight: '3vw',
				position: 'relative',
			},
			button: {
				color: textColor,
				fontSize: '1.2rem',
				fontFamily: 'Montserrat',
				textTransform: 'none',
				padding: '0 5px',
				marginRight: spacing(5),
				transition: '400ms opacity ease-in',
				borderRadius: '2px',
				display: 'inline-block',
				position: 'relative',
				'&::after': {
					transition: '400ms opacity ease-in',
				},

				[down('lg')]: {
					fontSize: '1rem',
					marginRight: spacing(2),
				},
			},
			activeLink: {
				'& > * button , & > button': {
					fontWeight: '800',
					'&::after': {
						position: 'absolute',
						content: '""',
						borderBottom: `2px solid ${textColor}`,
						width: '93%',
						transform: 'translateX(-50%)',
						bottom: '-8px',
						left: '50%',
					},
				},
			},
			itemsBox: {
				display: 'flex',
				flexDirection: 'row',
				margin: '0 auto',
				marginRight: '-5px',
			},
			vectors: {
				marginLeft: 'auto',
				color: textColor,
			},
		};
	});
