import React, { useCallback, useContext, useEffect, useState } from 'react';
import { NavLink, Link, useHistory } from 'react-router-dom';
import { AppBar, Toolbar, Hidden, IconButton, Button, Box } from '@material-ui/core';
import { Twitter, Facebook } from '@material-ui/icons';
import { Squash as Hamburger } from 'hamburger-react';
import { useStyle } from './useStyle';

import logo from '../../Assets/images/logo.svg';
import NestedMenu from './NestedMenu/NestedMenu';
import FreeConsLabel from './FreeConsLabel/FreeConsLabel';

import socialLinks from '../../Data/socialLinks.data';
import { data, themeObj } from '../../Data/navbar.data';
import { MobDrawerContext } from '../MobDrawer/MobDrawer';

const { links, pathThemes, initialTheme } = data;


const NavBar = (initTheme = initialTheme) => {
	const history = useHistory();
	const [drawerState, setDrawerState] = useContext(MobDrawerContext).drawer;
	const [theme, setTheme] = useState(initTheme);
	const themeHandler = useCallback(path => {
		const { txt, bg, pos, alpha } =
			Object.entries(pathThemes).find(
				el => el[0] === path || (el[0].endsWith('/') && path.startsWith(el[0]))
			)?.[1] || themeObj();
		setTheme({ pos: pos, bgColor: bg, txtColor: txt, alpha: alpha });
	}, []);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => themeHandler(history.location.pathname), []);
	history.listen(({ pathname }) => themeHandler(pathname));
	const classes = useStyle(theme)();

	const desktopMenu = (
		<Hidden smDown>
			<Box className={classes.itemsBox}>
				{links.map((link, i) => (
					<NavLink activeClassName={classes.activeLink} to={link[1]} key={i}>
						{link[2] ? (
							<NestedMenu btnClass={classes.button} links={link} />
						) : (
							<Button className={classes.button}>{link[0]}</Button>
						)}
					</NavLink>
				))}
			</Box>
			<IconButton href={socialLinks.twitter} target='_blank' style={{ marginLeft: 'auto' }}>
				<Twitter fontSize='large' className={classes.vectors} />
			</IconButton>
			<IconButton href={socialLinks.facebook} target='_blank'>
				<Facebook fontSize='large' className={classes.vectors} />
			</IconButton>
			<FreeConsLabel customClass={classes.consultation} theme={theme} />
		</Hidden>
	);

	const hamburgerIcon = (
		<Hidden smUp>
			<div
				className={classes.hamburger}
				onClick={e => {
					if (!drawerState) return setDrawerState(true);
					return false;
				}}>
				<Hamburger size={25} rounded={true} toggled={drawerState} />
			</div>
		</Hidden>
	);

	return (
		<AppBar className={classes.appBar} elevation={0}>
			<Toolbar>
				{hamburgerIcon}
				<Link to='/home'>
					<img src={logo} alt='logo' className={classes.logo} />
				</Link>
				{desktopMenu}
			</Toolbar>
		</AppBar>
	);
};

export default NavBar;
