import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import Theme from './Containers/Hoc/Theme/Theme';

import { BrowserRouter } from 'react-router-dom';

const app = (
	<BrowserRouter>
		<Theme>
			<App />
		</Theme>
	</BrowserRouter>
);

ReactDOM.render(app, document.getElementById('root'));

reportWebVitals();
