import { makeStyles } from '@material-ui/core';

export const useStyle = makeStyles(theme => {
	const { spacing } = theme;
	return {
		root: {
			color: 'white',
			fontFamily: 'Montserrat',
			margin: spacing(0.5, 0),
			width: '90%',
			'& p': {
				margin: 0,
			},
		},
		item: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			padding: spacing(1.5, 0),
			fontWeight: '500',
			width: '100%',
			borderRadius: '2px',
			transition: '200ms all linear',
			background: isDrawer => (isDrawer ? 'rgba(255,255,255,0.15)' : 'none'),
			'&:hover': {
				background: 'rgba(255,255,255,0.15)',
			},
			'& > img': {
				marginLeft: 'auto',
				marginRight: spacing(1),
			},
			'& p': {
				marginLeft: spacing(1),
				marginRight: 'auto',
				fontSize: '1.1rem',
			},
		},
		subBox: {
			margin: spacing(1, 0, 0, 2),
		},
		dividerLine: {
			marginTop: spacing(2),
			width: '100%',
			background: 'rgba(255,255,255,0.1)',
			height: '2px',
		},
	};
});
