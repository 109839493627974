import React, { useState } from 'react';
import { Collapse } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import upArrow from '../../../Assets/icons/up-arrow.svg';
import downArrow from '../../../Assets/icons/down-arrow.svg';
import { useStyle } from './useStyle';

const MobNestedMenuItem = ({ name, children, link, level }) => {
	const history = useHistory();
	const [isExpanded, setExpanded] = useState(false);
	const classes = useStyle(isExpanded);

	history.listen(setExpanded.bind(null, false));
	const itemClickHandler = () => {
		if (children) return setExpanded(pre => !pre);
		history.push(link);
	};

	return (
		<div className={classes.root}>
			<div className={classes.item} onClick={itemClickHandler}>
				<p>{name}</p>
				{children && <img src={isExpanded ? upArrow : downArrow} alt='expIcon' />}
			</div>
			<div className={classes.subBox}>
				<Collapse in={isExpanded}>{children}</Collapse>
			</div>
			{isExpanded && level === 0 && <div className={classes.dividerLine} />}
		</div>
	);
};

export default MobNestedMenuItem;
