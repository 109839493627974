import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles(theme => ({
	root: {
		display: 'flex',
	},
	// document.querySelector("#simple-popover > div.MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded")
	paper: {
		background: `rgba(0,0,0,0.4)`,
		// width: '130px',
		color: 'white',
		borderRadius: '2px',
		'& span': {
			fontFamily: 'Montserrat',
			fontWeight: '300',
			fontSize: '19px',
		},
		'& img': {
			width: '8px',
		},
		'& a': {
			color: 'white',
		},
	},
	item: {
		fontFamily: 'Montserrat !important',
		'& > li': {
			color: `${theme.palette.primary.main} !important`,
		},
	},
	secondBreadth: {
		color: theme.palette.primary.main,
		fontFamily: 'Montserrat',
		'&::parent': {
			background: 'red',
		},
	},
	divider: {
		width: '85%',
		margin: '3% 7.5%',
		background: 'white',
		height: '1px',
	},
}));
