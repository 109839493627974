import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';

const theme = createMuiTheme({
	direction: 'rtl',
	typography: {
		fontFamily: 'IRANSans',
	},

	palette: {
		primary: {
			main: '#2B2D2F',
			light: '#F3F3F3',
		},
		alternative: {
			main: '#F6F5E7',
			second: '#EFDEBD',
		},
		action: {
			main: '#D6B67C',
			second: '#74DAA8',
		},
	},
});

const Theme = props => <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;

export default Theme;
