import { makeStyles } from '@material-ui/core';

export const useStyle = ({ alpha = 1, bgColor = 'null' }) =>
	makeStyles(theme => {
		const {
			breakpoints: { down },
			palette,
		} = theme;
		const hasBg = alpha === 1 || bgColor === 'null';
		return {
			root: {
				background: hasBg ? 'rgba(255,255,255,0.5)' : 'none',
				fontFamily: 'Montserrat',
				fontWeight: hasBg ? 'bold' : '300',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				textAlign: 'center',
				borderRadius: '2px',
				width: '190px !important',
				height: '60px !important',
				'& > p': { margin: 0 },
				border: hasBg ? '0' : '2px solid rgba(255,255,255,0.5)',
				color: hasBg ? palette.primary.main : 'white',
			},
			number: {
				fontSize: '25px',
				opacity: '0.9',
				[down('md')]: {
					fontSize: '120%',
				},
			},
			text: {
				fontSize: '12px',
				opacity: '0.7',
				[down('md')]: {
					fontSize: '60%',
				},
			},
		};
	});
