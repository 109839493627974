import React, { useState, createContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Drawer } from '@material-ui/core';
import MobDrawerItems from './MobDrawerItems/MobDrawerItems';

export const MobDrawerContext = createContext({
	drawer: [],
});

const useStyle = makeStyles(theme => {
	const { palette, spacing } = theme;
	return {
		root: {
			'& .MuiDrawer-paper': {
				background: palette.primary.main,
				width: '67vw',
				padding: spacing(2, 0, 0, 2),
			},
			'& .MuiBackdrop-root': {
				backdropFilter: 'blur(3px)',
			},
		},
	};
});

const MobDrawer = ({ children }) => {
	const drawer = useState(false);
	const [drawerState, setDrawerState] = drawer;
	const history = useHistory();
	const classes = useStyle();

	history.listen(() => setDrawerState(false));

	const toggleDrawer = () => setDrawerState(pre => !pre);

	return (
		<MobDrawerContext.Provider value={{ drawer: drawer }}>
			<Drawer
				anchor='right'
				className={classes.root}
				open={drawerState}
				onClose={toggleDrawer}
				transitionDuration={600}
				onOpen={toggleDrawer}>
				<MobDrawerItems />
			</Drawer>
			{children}
		</MobDrawerContext.Provider>
	);
};

export default MobDrawer;
