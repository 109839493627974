import './App.css';
import React, { Suspense } from 'react';

import LoadingCover from '../Components/LoadingCover';
import Scrollbar from '../Components/Scrollbar';
import MobDrawer from '../Components/MobDrawer/MobDrawer';

import '../Assets/css/swiper-bundle.min.css';

import NavBar from '../Components/NavBar/NavBar';
import Routes from './Routes';
import Footer from '../Components/Footer/Footer';

import SwiperCore, { Mousewheel, Keyboard, Pagination } from 'swiper';
SwiperCore.use([Mousewheel, Keyboard, Scrollbar, Pagination]);

const App = () => {
	return (
		<Suspense fallback={<LoadingCover />}>
			<Scrollbar>
				<MobDrawer>
					<NavBar />
					<Routes />
					<Footer />
				</MobDrawer>
			</Scrollbar>
		</Suspense>
	);
};

export default App;
