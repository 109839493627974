import img1 from '../Assets/images/attorneys/ronald-kaplan.png';
import img2 from '../Assets/images/attorneys/susy-ross.png';

const data = [
	{
		path: '/ronald-kaplan',
		profile: {
			img: img1,
			shortName: 'Ronald Kaplan',
			name: 'Ronald I. Kaplan M.D., J.D.',
			description: 'Georgia’s Medical Malpractice and Personal Injury Lawyers',
			resume: [
				{
					title: 'Surgery Residency _ 1975_1980',
					link: '/#',
				},
				{
					title: 'Surgery Residency _ 1975_1980',
					link: '/#',
				},
				{
					title: 'Surgery Residency _ 1975_1980',
					link: '/#',
				},
			],
		},
		article: {
			header: 'Who Is Ronald Kaplan?',
			content: `Dr. Ronald Kaplan is a second generation native of Atlanta, and has lived here his entire life. He went to Briarcliff High School. He then attended Tulane University where he graduated with a major in Spanish and a minor in Chemistry in 1971. He received his medical degree in 1975 from the Medical College of Georgia, now known as the Georgia Health Sciences University.
            Dr. Kaplan did a general surgery residency from 1975 to 1980. In 1980 he opened his own surgery practice which he maintained until 2003. While in the practice of general surgery, Dr. Kaplan attended Georgia State University College of Law completing the program in 1992. Dr. Kaplan was admitted to the Georgia Bar in 2002 and since that time he has been engaged full time in advocating for the rights of people injured by medical error or by other wrongful acts.
            Dr. Kaplan’s dad, the late Judge Arthur Kaplan, created the first emergency services in Atlanta, where he trained hundreds of people in handling emergencies Judge Kaplan also trained hundreds of Atlanta Police Department and Atlanta Fire Department personnel in emergency first aid he also trained the GBI, FBI, and Secret Service in Atlanta. Dr. Kaplan has followed in his father’s footsteps. For many years he has actively responded to emergency calls at nights and on weekends. He was recognized in 1984 by Chief Morris Redding for his dedication to law enforcement and he currently holds the rank of Major in the Atlanta Police Department. He continues to teach the recruits at the Atlanta Police Academy and is also an instructor for the Sandy Springs Fire Department.
            Dr. Kaplan also works extensively with Russian immigrants, helping them to transition from living in a totalitarian society to our free society. Many of these immigrants came to the United States to escape religious persecution and economic hardships, and most spoke little, if any English. Dr. Kaplan assisted them in innumerable ways, including helping many of them find employment and with finding suitable housing. Over the years he has also provided these new citizens with free medical treatment and legal representation.
            Hundreds of people contact Dr. Kaplan every year in order to determine if they have a valid legal claim against a doctor or other health care provider. Dr. Kaplan is able to combine his medical and legal training to carefully review the medical record and can discuss the potential case with other physicians where necessary. Once we accept a case, Dr. Kaplan works aggressively on behalf of his clients.`,
		},
	},
	{
		path: '/susy-ross',
		profile: {
			img: img2,
			shortName: 'Susy Ross',
			name: 'Susy Ross',
			description: `Certificate of:
			Promoting Diversity & Inclusion Training`,
			resume: [
				{
					title: 'Surgery Residency _ 1975_1980',
					link: '/#',
				},
				{
					title: 'Surgery Residency _ 1975_1980',
					link: '/#',
				},
				{
					title: 'Surgery Residency _ 1975_1980',
					link: '/#',
				},
			],
		},
		article: {
			header: 'Who Is Susy Ross?',
			content: `Susy Ross is the ace senior paralegal who keeps the lawyers under control and on point. Susy is a native of Scotland born with wanderlust. After completing high school in Scotland, she attended psychiatric nursing school in Aberdeen Scotland. Susy studied hotel management in France then went to work in Israel for the summer of 1985. Susy returned to France where she lived for the following 15 years and held down a variety of jobs, including executive assistant, for a multinational firm, pre school teacher and English tutor. While raising two young children she completed her BTS in business administration. In 2000 she and her children immigrated to the United States, settling in Marietta, Georgia. Susy is a certified Integrative Nutrition Health Coach, recently graduating from Institute for Integrative Nutrition (IIN).

			Susy obtained her paralegal certification at Kennesaw State University in 2003 when she also became a registered French interpreter. Before coming to our office, she worked for a well known insurance defense firm. Having seen the way insurance companies handle the claims of injured victims, she decided that she would only work for a firm that represented injured people. Susy joined us in 2009 and has been our mainstay ever since. She recently completed her Promoting Diversity & Inclusion training for the firm.`,
		},
	},
];
export default data;
