const year = new Date().getFullYear();

const data = {
	copyRight: {
		left: `© ${year}. All Rights Reserved.`,
		right: `© ${year}. All Rights Reserved. Design by WIX @ Xebra, Inc`,
	},
	location: {
		address: `333 Sandy Springs Circle,
		Suite 200 
		Atlanta, GA 30328`,
	},
	contact: {
		phone: 'Phone: (404) 845-0012',
		fax: 'Fax: (404) 845-0028',
	},
	items: {
		desktop: {
			Company: [
				['About Us', '/about-us/legal-team/ronald-kaplan'],
				['Areas of Law Practice', '/areas-of-law-practices'],
				['Testimonials', '/about-us/testimonials'],
				['Contact Us', '/contact-us'],
			],
			Other: [
				// ['Blog', '/blog'],
				['FAQ', '/faq'],
			],
		},
		mobile: {
			'Our Company': [
				['About Us', '/about-us/legal-team/ronald-kaplan'],
				['Areas of Law Practices', '/areas-of-law-practices'],
				// ['Blog', '/blog'],
				['FAQ', '/faq'],
				['Contact Us', '/contact-us'],
				['Testimonials', '/about-us/testimonials'],
			],
		},
	},
};

export default data;
