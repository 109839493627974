import React from 'react';
import { useStyle } from './useStyle';

const FreeConsLabel = ({ theme = {}, customClass = '' }) => {
	const classes = useStyle(theme)();
	return (
		<div className={[classes.root, customClass].join(' ')}>
			<p className={classes.number}>404-845-0012</p>
			<p className={classes.text}>FREE CONSULTATION</p>
		</div>
	);
};

export default FreeConsLabel;
